<template>
    <div class="side-nav" id="side-nav">
        <div class="side-nav-content">
            <ul class="side-nav-menu">
                <li class="menu-item menu-item-title">Home</li>
                <li class="menu-item">
                    <router-link :to="{ name: 'dashboard' }" class="menu-link">Dashboard</router-link>
                </li>
                <li class="menu-item menu-item-title">UI</li>
                <li class="menu-item menu-item-parent dropdown">
                    <router-link to="/ui" event="" @click.native.prevent class="menu-link dropdown-toggle">UI Elements</router-link>
                    <ul class="sub-menu">
                        <li class="menu-item">
                            <router-link to="/ui/alerts" class="menu-link">Alerts</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/ui/avatars" class="menu-link">Avatars</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/ui/badge" class="menu-link">Badge</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/ui/breadcrumb" class="menu-link">Breadcrumb</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/ui/buttons" class="menu-link">Buttons</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/calendar" class="menu-link">Calendar</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/cards" class="menu-link">Cards</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/dropdowns" class="menu-link">Dropdown</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/modal" class="menu-link">Modal</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/notifications" class="menu-link">Notifications</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/pagination" class="menu-link">Pagination</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/panels" class="menu-link">Panels</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/progress" class="menu-link">Progress</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/spinners" class="menu-link">Spinners</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/table" class="menu-link">Table</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/tabs" class="menu-link">Tabs</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/time" class="menu-link">Time</router-link></li>
                        <li class="menu-item">
                            <router-link to="/ui/typography" class="menu-link">Typography</router-link></li>
                    </ul>
                </li>
                <li class="menu-item menu-item-title">Forms</li>
                <li class="menu-item">
                    <router-link to="/forms/elements" class="menu-link">Input Elements</router-link>
                </li>
                <li class="menu-item menu-item-title">Layouts</li>
                <li class="menu-item menu-item-parent">
                    <router-link to="/layouts" event="" @click.native.prevent class="menu-link">Page Layouts</router-link>
                    <ul class="sub-menu">
                        <li class="menu-item">
                            <router-link to="/layouts/wizard-layout" class="menu-link">Wizard Layout</router-link>
                        </li>
                    </ul>
                </li>
                <li class="menu-item menu-item-title">Pages</li>
                <li class="menu-item menu-item-parent">
                    <router-link to="/pages" event="" @click.native.prevent class="menu-link">Sample Pages</router-link>
                    <ul class="sub-menu">
                        <li class="menu-item">
                            <router-link to="/pages/authentication" class="menu-link">Authentication</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/profile" class="menu-link">My Profile</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/vehicles" class="menu-link">My Vehicles</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/services" class="menu-link">My Services</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/credentials" class="menu-link">My Credentials</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/setup-profile" class="menu-link">Setup Profile</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/vehicle-selection" class="menu-link">Vehicle Selection</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/route-work-a" class="menu-link">Mapping - Route Work A</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/route-work-b" class="menu-link">Mapping - Route Work B</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/multi-stop-a" class="menu-link">Mapping - Multi Stop A</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/multi-stop-b" class="menu-link">Mapping - Multi Stop B</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/multi-stop-c" class="menu-link">Mapping - Multi Stop C</router-link>
                        </li>
                        <li class="menu-item">
                            <router-link to="/pages/multi-stop-d" class="menu-link">Mapping - Multi Stop D</router-link>
                        </li>
                    </ul>
                </li>
                <li class="menu-item menu-item-title">Widgets</li>
                <li class="menu-item menu-item-parent">
                    <router-link to="/widgets" class="menu-link">Custom Widgets</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SideNav',
        methods: {
            handleItemClick(el, item) {
                if (item.collapsible) {
                    const elem = window.$(el);
                    const menuItem = elem.closest('.menu-item');

                    window.$('.menu-item').not(menuItem).removeClass('open').find('.sub-menu').slideUp(100);

                    menuItem.toggleClass('open').find('.sub-menu').slideToggle(100);
                } else {
                    this.$router.push(item);
                }
            },
            menuHasChildren(menu) {
                return menu.children && menu.children.length;
            }
        }
    }
</script>
