<template>
    <div class="footer-social">
        <h3 class="footer-title">Social Media</h3>
        <ul class="footer-social-menu">
            <li>
                <a href="#">
                    <fa :icon="['fab', 'facebook-f']" />
                </a>
            </li>
            <li>
                <a href="#">
                    <fa :icon="['fab', 'twitter']" />
                </a>
            </li>
            <li>
                <a href="#">
                    <fa :icon="['fab', 'instagram']" />
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'FooterSocial'
    }
</script>
