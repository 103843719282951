<template>
    <div class="container-fluid px-0">
        <div class="wizard-container" :style="wizardBgStyle">
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
        <footer-nav />
    </div>
</template>

<script>
    import FooterNav from '@/components/FooterNav';
    import wizardBg from '../assets/images/wizard-bg.png';

    export default {
        name: 'BaseWizardLayout',
        components: {
            FooterNav
        },
        computed: {
            wizardBgStyle() {
                return {
                    backgroundImage: `url(${wizardBg})`
                }
            }
        }
    }
</script>
