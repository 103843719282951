<template>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top top-header">
        <div class="container-fluid px-0">
            <div class="d-flex align-items-center">
                <button class="navbar-toggler text-white" type="button" aria-label="Toggle navigation">
                    <fa icon="bars" />
                </button>
                <router-link to="/" class="navbar-brand">
                    <nav-logo />
                </router-link>
            </div>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <fa icon="info-circle" class="nav-link-icon" />
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <fa icon="bell" class="nav-link-icon" />
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <fa icon="cog" class="nav-link-icon" />
                        </a>
                    </li>
                </ul>
            </div>
            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle dropdown-toggle-icon d-flex align-items-center" href="javascript:void(0)" role="button" data-toggle="dropdown">
                        <span class="d-none d-sm-inline">Display Name</span>
                        <fa icon="chevron-down" class="nav-link-icon" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right position-absolute">
                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import NavLogo from "../NavLogo";

    export default {
        name: 'Navbar',
        components: {
            NavLogo
        }
    }
</script>
