import Vue from 'vue'
import VueRouter from 'vue-router'
import ThemeLayout from '../layouts/ThemeLayout';
import BaseWizardLayout from '../layouts/BaseWizardLayout';
import ProfileLayout from "../layouts/ProfileLayout";
import DefaultLayout from "../layouts/DefaultLayout";

Vue.use(VueRouter)

export const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: ThemeLayout
    },
    {
        path: '/ui',
        component: ThemeLayout,
        children: [
            {
                path: 'alerts',
                name: 'ui/alerts',
                component: () => import(/* webpackChunkName: "ui-alerts" */ '../views/ui/Alerts.vue')
            },
            {
                path: 'avatars',
                name: 'ui/avatars',
                component: () => import(/* webpackChunkName: "ui-avatars" */ '../views/ui/Avatars.vue')
            },
            {
                path: 'badge',
                name: 'ui/badge',
                component: () => import(/* webpackChunkName: "ui-badge" */ '../views/ui/Badge.vue')
            },
            {
                path: 'breadcrumb',
                name: 'ui/breadcrumb',
                component: () => import(/* webpackChunkName: "ui-breadcrumb" */ '../views/ui/Breadcrumb.vue')
            },
            {
                path: 'buttons',
                name: 'ui/buttons',
                component: () => import(/* webpackChunkName: "ui-buttons" */ '../views/ui/Buttons.vue')
            },
            {
                path: 'calendar',
                name: 'ui/calendar',
                component: () => import(/* webpackChunkName: "ui-calendar" */ '../views/ui/Calendar.vue')
            },
            {
                path: 'cards',
                name: 'ui/cards',
                component: () => import(/* webpackChunkName: "ui-cards" */ '../views/ui/Cards.vue')
            },
            {
                path: 'dropdowns',
                name: 'ui/dropdowns',
                component: () => import(/* webpackChunkName: "ui-dropdowns" */ '../views/ui/Dropdowns.vue')
            },
            {
                path: 'modal',
                name: 'ui/modal',
                component: () => import(/* webpackChunkName: "ui-modal" */ '../views/ui/Modal.vue')
            },
            {
                path: 'notifications',
                name: 'ui/notifications',
                component: () => import(/* webpackChunkName: "ui-notifications" */ '../views/ui/Notifications.vue')
            },
            {
                path: 'pagination',
                name: 'ui/pagination',
                component: () => import(/* webpackChunkName: "ui-pagination" */ '../views/ui/Pagination.vue')
            },
            {
                path: 'panels',
                name: 'ui/panels',
                component: () => import(/* webpackChunkName: "ui-panels" */ '../views/ui/Panels.vue')
            },
            {
                path: 'progress',
                name: 'ui/progress',
                component: () => import(/* webpackChunkName: "ui-progress" */ '../views/ui/Progress.vue')
            },
            {
                path: 'spinners',
                name: 'ui/spinners',
                component: () => import(/* webpackChunkName: "ui-spinners" */ '../views/ui/Spinners.vue')
            },
            {
                path: 'table',
                name: 'ui/table',
                component: () => import(/* webpackChunkName: "ui-table" */ '../views/ui/Table.vue')
            },
            {
                path: 'tabs',
                name: 'ui/tabs',
                component: () => import(/* webpackChunkName: "ui-tabs" */ '../views/ui/Tabs.vue')
            },
            {
                path: 'time',
                name: 'ui/time',
                component: () => import(/* webpackChunkName: "ui-time" */ '../views/ui/Time.vue')
            },
            {
                path: 'typography',
                name: 'ui/typography',
                component: () => import(/* webpackChunkName: "ui-typography" */ '../views/ui/Typography.vue')
            },
        ]
    },
    {
        path: '/forms',
        component: ThemeLayout,
        children: [
            {
                path: 'elements',
                name: 'forms/elements',
                component: () => import(/* webpackChunkName: "forms-elements" */ '../views/forms/FormElements.vue')
            }
        ]
    },
    {
        path: '/layouts',
        component: BaseWizardLayout,
        children: [
            {
                path: 'wizard-layout',
                name: 'layouts/wizard-layout',
                component: () => import(/* webpackChunkName: "layouts-wizard-layout" */ '../views/layouts/WizardLayout.vue')
            }
        ]
    },
    {
        path: '/pages',
        component: BaseWizardLayout,
        children: [
            {
                path: 'authentication',
                name: 'pages/authentication',
                component: () => import(/* webpackChunkName: "pages-authentication" */ '../views/pages/Authentication.vue')
            }
        ]
    },
    {
        path: '/pages',
        component: ProfileLayout,
        children: [
            {
                path: 'profile',
                name: 'pages/profile',
                component: () => import(/* webpackChunkName: "pages-profile-layout" */ '../views/pages/ProfileLayout.vue')
            },
            {
                path: 'vehicles',
                name: 'pages/vehicles',
                component: () => import(/* webpackChunkName: "pages-vehicles" */ '../views/pages/Vehicles.vue')
            },
            {
                path: 'services',
                name: 'pages/services',
                component: () => import(/* webpackChunkName: "pages-services" */ '../views/pages/Services.vue')
            },
            {
                path: 'credentials',
                name: 'pages/credentials',
                component: () => import(/* webpackChunkName: "pages-credentials" */ '../views/pages/Credentials.vue')
            }
        ]
    },
    {
        path: '/pages',
        component: BaseWizardLayout,
        children: [
            {
                path: 'setup-profile',
                name: 'pages/setup-profile',
                component: () => import(/* webpackChunkName: "pages-setup-profile" */ '../views/pages/SetupProfile.vue')
            },
            {
                path: 'vehicle-selection',
                name: 'pages/vehicle-selection',
                component: () => import(/* webpackChunkName: "pages-vehicle-selection" */ '../views/pages/VehicleSelection.vue')
            }
        ]
    },
    {
        path: '/pages',
        component: DefaultLayout,
        children: [
            {
                path: 'route-work-a',
                name: 'pages/route-work-a',
                component: () => import(/* webpackChunkName: "pages-route-work-a" */ '../views/pages/RouteWorkA.vue')
            },
            {
                path: 'route-work-b',
                name: 'pages/route-work-b',
                component: () => import(/* webpackChunkName: "pages-route-work-b" */ '../views/pages/RouteWorkB.vue')
            },
            {
                path: 'multi-stop-a',
                name: 'pages/multi-stop-a',
                component: () => import(/* webpackChunkName: "pages-multi-stop-a" */ '../views/pages/MultiStopA.vue')
            },
            {
                path: 'multi-stop-b',
                name: 'pages/multi-stop-b',
                component: () => import(/* webpackChunkName: "pages-multi-stop-b" */ '../views/pages/MultiStopB.vue')
            },
            {
                path: 'multi-stop-c',
                name: 'pages/multi-stop-c',
                component: () => import(/* webpackChunkName: "pages-multi-stop-c" */ '../views/pages/MultiStopC.vue')
            },
            {
                path: 'multi-stop-d',
                name: 'pages/multi-stop-d',
                component: () => import(/* webpackChunkName: "pages-multi-stop-d" */ '../views/pages/MultiStopD.vue')
            }
        ]
    },
    {
        path: '/widgets',
        component: ThemeLayout,
        children: [
            {
                path: '',
                name: 'widgets',
                component: () => import(/* webpackChunkName: "widgets" */ '../views/Widgets.vue')
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.afterEach(() => {
    setTimeout(() => {
        window.$('.router-link-active').closest('.menu-item-parent').addClass('open');
    }, 100)
})

export default router
