<template>
    <footer class="footer-nav">
        <div class="container px-0">
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 mb-n4">
                <div class="col">
                    <footer-menu v-bind="menus.about" class="mb-4" />
                </div>
                <div class="col">
                    <footer-menu v-bind="menus.job_seekers" class="mb-4" />
                </div>
                <div class="col">
                    <footer-menu v-bind="menus.employers" class="mb-4" />
                </div>
                <div class="col">
                    <footer-menu v-bind="menus.support" class="mb-4" />
                    <footer-social />
                </div>
            </div>
            <div class="footer-copyright">2020 - 2021 E-Courier - Co-Foundry</div>
        </div>
    </footer>
</template>

<script>
    import FooterMenu from "../FooterMenu";
    import FooterSocial from "../FooterSocial";

    export default {
        name: 'FooterNav',
        components: {
            FooterSocial,
            FooterMenu
        },
        data() {
            return {
                menus: {
                    about: {
                        title: 'About',
                        items: [
                            {label: 'Inquiry'},
                            {label: 'Terms of Use'},
                            {label: 'Partners'},
                            {label: 'Advertisers'},
                        ]
                    },
                    job_seekers: {
                        title: 'Job Seekers',
                        items: [
                            {label: 'Search for a job'},
                            {label: 'Search by location'},
                            {label: 'Search by company'},
                            {label: 'Using your dashboard'},
                            {label: 'Couriers Q&A'},
                        ]
                    },
                    employers: {
                        title: 'Employers',
                        items: [
                            {label: 'Post a job'},
                            {label: 'Features'},
                            {label: 'Pricing'},
                            {label: 'Poster Q&A'},
                            {label: 'Employer Login'},
                        ]
                    },
                    support: {
                        title: 'Support',
                        items: [
                            {label: 'Submit a ticket'},
                            {label: 'Contact us'},
                        ]
                    }
                }
            }
        }
    }
</script>
