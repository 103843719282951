<template>
    <div class="theme-wrapper">
        <navbar />
        <page-header />
        <side-nav class="side-nav-header-adjust" />
        <div class="page-container page-container-header-adjust">
            <div class="page-content">
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </div>
            <footer class="page-footer">2020 - 2021 E-Courier - Co-Foundry</footer>
        </div>
    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    import SideNav from "../components/SideNav";
    import PageHeader from "../components/PageHeader/PageHeader";

    export default {
        name: 'ProfileLayout',
        components: {
            PageHeader,
            SideNav,
            Navbar
        }
    }
</script>
