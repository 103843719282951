import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Bootstrap
import 'bootstrap';
import jQuery from 'jquery';
import Popper from "popper.js";
// Bootstrap Vue
import { BootstrapVue } from "bootstrap-vue";
// Font Awesome
import { library as faLibrary, dom as faDom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

window.Popper = Popper;
window.$ = window.jQuery = jQuery;

Vue.use(BootstrapVue);

faLibrary.add(fas, fab);
faDom.watch();

Vue.component('fa', FontAwesomeIcon);

// App styles
import './assets/scss/style.scss'

// Theme scripts
import './theme';

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
