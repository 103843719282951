<template>
    <div :class="['star-rating', {'rateable': rateable}]">
        <fa v-for="star in max" :key="star" icon="star" :class="['star', { 'active': isActive(star) }]" />
    </div>
</template>

<script>
    export default {
        name: 'StarRating',
        props: {
            rating: {
                type: Number,
                required: true,
                default: 0
            },
            rateable: Boolean
        },
        data() {
            return {
                max: 5
            }
        },
        methods: {
            isActive(star) {
                return star <= this.rating;
            }
        }
    }
</script>
