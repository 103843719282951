<template>
    <header class="page-header">
        <user-summary :user="user" />
    </header>
</template>

<script>
    import UserSummary from "../UserSummary/UserSummary";
    export default {
        name: 'PageHeader',
        components: {
            UserSummary
        },
        data() {
            return {
                user: {
                    company_name: 'Company Name',
                    full_name: 'Name Surname',
                    zip_code: 'Zip Code',
                    reviews_count: 24,
                    rating: 3,
                    profile_photo_url: '/assets/images/avatar.png'
                }
            }
        }
    }
</script>
