<template>
    <img :src="logo" :alt="alt" class="nav-logo" />
</template>

<script>
    import logo from '../../assets/images/logo-text.svg'

    export default {
        name: 'NavLogo',
        props: {
            alt: {
                type: String,
                default: 'E-Courier'
            }
        },
        data() {
            return {
                logo
            }
        }
    }
</script>
