<template>
    <div class="footer-menu-container">
        <h3 class="footer-title">{{ title }}</h3>
        <ul class="footer-menu">
            <li v-for="(item, index) in items" :key="index">
                <a href="#">{{ item.label }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'FooterMenu',
        props: {
            title: {
                type: String,
                required: true
            },
            items: {
                type: Array,
                required: true
            }
        }
    }
</script>
