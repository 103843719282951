<template>
    <div class="theme-wrapper">
        <navbar />
        <side-nav />
        <div class="page-container">
            <div class="page-content">
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </div>
            <footer class="page-footer">2020 - 2021 E-Courier - Co-Foundry</footer>
        </div>
    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    import SideNav from "../components/SideNav";

    export default {
        name: 'ThemeLayout',
        components: {
            SideNav,
            Navbar
        }
    }
</script>
