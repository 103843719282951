<template>
    <div class="user-summary">
        <div class="user-summary-avatar avatar-md">
            <img :src="user.profile_photo_url" :alt="user.full_name" />
            <span class="user-summary-rating">{{ user.rating }}</span>
        </div>
        <div class="user-summary-info">
            <div class="user-info user-info-heading">{{ user.company_name }}</div>
            <div class="user-info font-weight-bold">{{ user.full_name }}</div>
            <div class="user-info">{{ user.zip_code }} | {{ user.reviews_count }} Reviews</div>
            <star-rating :rating="user.rating" />
        </div>
    </div>
</template>

<script>
    import StarRating from '../StarRating';

    export default {
        name: 'UserSummary',
        components: {
            StarRating
        },
        props: {
            user: {
                type: Object,
                required: true
            }
        }
    }
</script>
